<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <BaseButton
          color="secondary"
          title="Iniciar nova implantação"
          @click="init"
          v-permission="{
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }"
        />
      </template>
    </BaseTitlePage>

    <BaseSearchPage title="Consultar e filtrar por contratos existentes">
      <template v-slot:form>
        <v-row dense>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <BaseCustomersAutocomplete
              label="Cliente"
              clearable
              hide-details
              v-model="filter.customerId"
            />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="6">
            <v-autocomplete
              label="Status"
              clearable
              dense
              hide-details
              outlined
              :items="status"
              v-model="filter.situation"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="2" sm="6">
            <BaseButton
              color="primary"
              title="Filtrar"
              icon="fa-solid fa-magnifying-glass"
              height="40"
              @click="search"
            />
          </v-col>
        </v-row>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de clientes em implantação">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.customerName`]="{ item }">
            <span :title="item.customerName">
              {{ doTruncateText(item.customerName, 30) }}
            </span>
          </template>

          <template v-slot:[`item.customerDocument`]="{ item }">
            {{ formatCnpj(item.customerDocument) }}
          </template>

          <template v-slot:[`item.healthEffectiveDate`]="{ item }">
            <span v-if="item.healthEffectiveDate">
              {{ formatDate(item.healthEffectiveDate) }}
            </span>
            <v-icon v-else color="error" size="21">
              fa-regular fa-circle-xmark
            </v-icon>
          </template>

          <template v-slot:[`item.lifeEffectiveDate`]="{ item }">
            <span v-if="item.lifeEffectiveDate">
              {{ formatDate(item.lifeEffectiveDate) }}
            </span>
            <v-icon v-else color="error" size="21">
              fa-regular fa-circle-xmark
            </v-icon>
          </template>

          <template v-slot:[`item.dentalEffectiveDate`]="{ item }">
            <span v-if="item.dentalEffectiveDate">
              {{ formatDate(item.dentalEffectiveDate) }}
            </span>
            <v-icon v-else color="error" size="21">
              fa-regular fa-circle-xmark
            </v-icon>
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <span :class="getColor(item.situation)">{{ item.situation }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="edit(item)"
                  v-permission="{
                    roles: [
                      'A1',
                      'AC1',
                      'AC2',
                      'AC3',
                      'ACI1',
                      'ACI2',
                      'ACI3',
                      'CC1',
                      'CC2'
                    ],
                    operator: '==='
                  }"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <template v-if="item.situation === 'Novo'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="error"
                    small
                    @click="deleteImplementation(item)"
                    v-permission="{
                      roles: [
                        'A1',
                        'AC1',
                        'AC2',
                        'AC3',
                        'ACI1',
                        'ACI2',
                        'ACI3',
                        'CC1',
                        'CC2'
                      ],
                      operator: '==='
                    }"
                  >
                    fa-regular fa-trash-can
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>
  </v-container>
</template>

<script>
import { ImplementationService } from '@/services/api/implementation';
import { getColor, doTruncateText } from '@/helpers/utils';
import { formatCnpj, formatDate } from '@/helpers/formatting';
import { confirmMessage, showMessage } from '@/helpers/messages';

export default {
  data: () => ({
    items: [],
    totalRecords: 0,
    filter: {},
    options: {},
    status: ['Novo', 'Em análise', 'Em revisão', 'Concluído'],
    headers: [
      { text: 'Cliente', value: 'customerName' },
      { text: 'CNPJ', value: 'customerDocument' },
      { text: 'Saúde', value: 'healthEffectiveDate' },
      { text: 'Vida', value: 'lifeEffectiveDate' },
      { text: 'Odonto', value: 'dentalEffectiveDate' },
      { text: 'Status', value: 'situation' },
      { text: '', value: 'action', width: '10%' }
    ]
  }),

  methods: {
    formatCnpj,
    formatDate,
    getColor,
    doTruncateText,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const { customerId, situation } = this.filter;
        const implementationService = new ImplementationService();
        const { status, data } = await implementationService.getImplementations(
          {
            page,
            take: itemsPerPage,
            customerId,
            situation
          }
        );

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        } else {
          this.items = [];
          this.totalRecords = 0;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    init() {
      this.$router.push({ name: 'implantacoes-new' });
    },

    edit(item) {
      this.$router.push({
        name: 'implantacoes-edit',
        params: { id: item.id, situation: item.situation }
      });
    },

    async deleteImplementation(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        try {
          const implementationService = new ImplementationService();
          const { status } = await implementationService.deleteImplementation(
            item.id
          );

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
