var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BaseTitlePage',{scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('BaseButton',{directives:[{name:"permission",rawName:"v-permission",value:({
          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
          operator: '==='
        }),expression:"{\n          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n          operator: '==='\n        }"}],attrs:{"color":"secondary","title":"Iniciar nova implantação"},on:{"click":_vm.init}})]},proxy:true}])}),_c('BaseSearchPage',{attrs:{"title":"Consultar e filtrar por contratos existentes"},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('BaseCustomersAutocomplete',{attrs:{"label":"Cliente","clearable":"","hide-details":""},model:{value:(_vm.filter.customerId),callback:function ($$v) {_vm.$set(_vm.filter, "customerId", $$v)},expression:"filter.customerId"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-autocomplete',{attrs:{"label":"Status","clearable":"","dense":"","hide-details":"","outlined":"","items":_vm.status},model:{value:(_vm.filter.situation),callback:function ($$v) {_vm.$set(_vm.filter, "situation", $$v)},expression:"filter.situation"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"6"}},[_c('BaseButton',{attrs:{"color":"primary","title":"Filtrar","icon":"fa-solid fa-magnifying-glass","height":"40"},on:{"click":_vm.search}})],1)],1)]},proxy:true}])}),_c('BaseTableList',{attrs:{"title":"Listagem de clientes em implantação"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.customerName`,fn:function({ item }){return [_c('span',{attrs:{"title":item.customerName}},[_vm._v(" "+_vm._s(_vm.doTruncateText(item.customerName, 30))+" ")])]}},{key:`item.customerDocument`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatCnpj(item.customerDocument))+" ")]}},{key:`item.healthEffectiveDate`,fn:function({ item }){return [(item.healthEffectiveDate)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.healthEffectiveDate))+" ")]):_c('v-icon',{attrs:{"color":"error","size":"21"}},[_vm._v(" fa-regular fa-circle-xmark ")])]}},{key:`item.lifeEffectiveDate`,fn:function({ item }){return [(item.lifeEffectiveDate)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.lifeEffectiveDate))+" ")]):_c('v-icon',{attrs:{"color":"error","size":"21"}},[_vm._v(" fa-regular fa-circle-xmark ")])]}},{key:`item.dentalEffectiveDate`,fn:function({ item }){return [(item.dentalEffectiveDate)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.dentalEffectiveDate))+" ")]):_c('v-icon',{attrs:{"color":"error","size":"21"}},[_vm._v(" fa-regular fa-circle-xmark ")])]}},{key:`item.situation`,fn:function({ item }){return [_c('span',{class:_vm.getColor(item.situation)},[_vm._v(_vm._s(item.situation))])]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                  roles: [
                    'A1',
                    'AC1',
                    'AC2',
                    'AC3',
                    'ACI1',
                    'ACI2',
                    'ACI3',
                    'CC1',
                    'CC2'
                  ],
                  operator: '==='
                }),expression:"{\n                  roles: [\n                    'A1',\n                    'AC1',\n                    'AC2',\n                    'AC3',\n                    'ACI1',\n                    'ACI2',\n                    'ACI3',\n                    'CC1',\n                    'CC2'\n                  ],\n                  operator: '==='\n                }"}],staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_vm._v(" fa-solid fa-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),(item.situation === 'Novo')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                    roles: [
                      'A1',
                      'AC1',
                      'AC2',
                      'AC3',
                      'ACI1',
                      'ACI2',
                      'ACI3',
                      'CC1',
                      'CC2'
                    ],
                    operator: '==='
                  }),expression:"{\n                    roles: [\n                      'A1',\n                      'AC1',\n                      'AC2',\n                      'AC3',\n                      'ACI1',\n                      'ACI2',\n                      'ACI3',\n                      'CC1',\n                      'CC2'\n                    ],\n                    operator: '==='\n                  }"}],attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteImplementation(item)}}},on),[_vm._v(" fa-regular fa-trash-can ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]:_vm._e()]}}],null,true)})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }